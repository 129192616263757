
/***************************************************************************************************
 * IE11 does not support "new CustomEvent..." used by angular-oidc-client"
 * Workaround: emulate..
 *
 */
(function () {
  const $window = <any>window;
  if (typeof $window.CustomEvent === 'function') { return false; }

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = $window.Event.prototype;
  $window.CustomEvent = CustomEvent;
})();

declare module 'regexp' {
  interface RegExp<T> {
    compile(exp: any, flag: any): any;
  }
}

if (!RegExp.prototype[ 'compile' ]) {
  RegExp.prototype.compile = function (exp?: any, flag?: any) {
    console.log('polyfill call', exp, flag);
    return new RegExp(exp, flag);
  };
}
